.stage-cube-cont {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 5rem;
    margin-bottom: 10rem;
}

.cubespinner-night {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);

    div {
        position: absolute;
        width: 200px;
        height: 200px;
        border: 1px solid #ccc;
        background: rgba(255, 255, 255, 0.4);
        text-align: center;
        font-size: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 20px 0px lightyellow;
        svg{
                width: 100px;
                height: 100px;
        }
        @media screen and (max-width: 800px) {
            width: 100px;
            height: 100px;
            svg {
                width: 70px;
                height: 70px;
            }
        }
    }

    .face1 {
        transform: translateZ(100px);
        color: #dd0031;
        @media screen and (max-width: 800px) {
            transform: translateZ(50px);
        }
       
    }

    .face2 {
        transform: rotateY(90deg) translateZ(100px);
        color: #f06529;
        @media screen and (max-width: 800px) {
            transform: rotateY(90deg) translateZ(50px);
        }
    }

    .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
        color: #28a4d9;
        @media screen and (max-width: 800px) {
            transform: rotateY(90deg) rotateX(90deg) translateZ(50px);
        }
    }

    .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
        color: #5ed4f4;
        @media screen and (max-width: 800px) {
            transform: rotateY(180deg) rotateZ(90deg) translateZ(50px);
        }
    }

    .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
        color: #efd81d;
        @media screen and (max-width: 800px) {
            transform:rotateY(-90deg) rotateZ(90deg) translateZ(50px);
        }
    }

    .face6 {
        transform: rotateX(-90deg) translateZ(100px);
        color: #ec4d28;
        @media screen and (max-width: 800px) {
            transform: rotateX(-90deg) translateZ(50px);
        }
    }
}
.cubespinner-day {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);

    div {
        position: absolute;
        width: 200px;
        height: 200px;
        border: 1px solid rgb(123, 123, 123);
        background: rgba(0, 0, 0, 0.3);
        text-align: center;
        font-size: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 20px 0px rgb(57, 57, 57);
        svg{
                width: 100px;
                height: 100px;
        }
    }

    .face1 {
        transform: translateZ(100px);
        color: #dd0031;
       
    }

    .face2 {
        transform: rotateY(90deg) translateZ(100px);
        color: #f06529;
    }

    .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
        color: #28a4d9;
    }

    .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
        color: #5ed4f4;
    }

    .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
        color: #efd81d;
    }

    .face6 {
        transform: rotateX(-90deg) translateZ(100px);
        color: #ec4d28;
    }
}

@keyframes spincube {

    from,
    to {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg); 
    }

    16% {
        transform: rotateY(-90deg);
    }

    33% {
        transform: rotateY(-90deg) rotateZ(90deg);
    }

    50% {
        transform: rotateY(-180deg) rotateZ(90deg);
    }

    66% {
        transform: rotateY(-270deg) rotateX(90deg);
    }

    83% {
        transform: rotateX(90deg);
    }
}