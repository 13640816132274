nav {
  font-family: sans-serif;
  display: flex;
  padding: 1rem 5rem;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  /* border: 1px solid rgba(255, 255, 255, 0.18); */
  align-items: center;
  width: -webkit-fill-available;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: -2px 0 8px 1px grey;
  z-index: 1001;
  height: 3rem;
}

nav .logo{
  height: 2.5rem;
}

.logo img{
  height: 100%;
  /* width: 100%; */
}

.fas {
  cursor: pointer;
}

.menu-icon {
  display: none;
  font-size: 20px;
}
.menu-icon .fa-times {
  transform: rotate(360deg);
  transition: all 0.2s ease-in-out;
}

.menu-icon .fa-bars {
  transform: rotate(180deg);
  transition: all 0.2s ease-in-out;
}

.logo {
  font-weight: 600;
  font-size: 25px;
  color: black;
}



.menu-list {
  color: #000;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.5s ease-out;
  opacity: 1;
}

.menu-list li:last-child a {
  font-weight: 300;
  padding: 5px 10px;
  /* background-color: #01aacd; */
  transition: all 0.5s ease-in-out;
  margin-left: 20px;
  border-radius: 2px;
}

.menu-list li a {
  text-decoration: none;
  color: black;
  font-weight: 300;
  padding: 5px 10px;
  margin-left: 20px;
}

.menu-list li a:hover,
.menu-list li a.active {
  background-color: rgba(86, 86, 86, 0.386);
  border-radius: 2px;
  color: #000;
  transition: all 0.5s ease-in-out;
}

@media screen and (max-width: 883px) {
  nav{
      padding: 1rem 0;
      justify-content: space-around;
  }
  .logo {
    font-size: 20px;
  }
  .menu-list li a {
    font-size: 14px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 800px) {
  .menu-icon {
    display: block;
  }
  .menu-list {
    flex-direction: column;
    width: 100%;
    position: absolute;
      background: rgba(255, 255, 255, 0.9);
    padding: 20px 0;
    right: 0;
    top: 5rem;
    opacity: 1;
  }

  .menu-list li {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }
  .menu-list li:last-child {
    margin-bottom: 0px;
  }

  .menu-list li a,
  .menu-list li:last-child a {
    width: 100px;
    display: block;
    margin: auto;
  }

  .menu-list.close {
    display: none;

    opacity: 1;
  }
}

@media screen and (max-width: 800px) {
  .menu-list li a {
 
    color: color;
  }
  .menu-list li a:hover,
  .menu-list li a.active {
    background-color: rgba(166, 166, 166, 0.386);
    border-radius: 2px;
    color: color;
    transition: all 0.5s ease-in-out;
  }
}