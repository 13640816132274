@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

/* .container-main{
margin: 0;
padding: 0;
box-sizing: border-box;
font-family: 'Poppins', sans-serif;
} */

/* body {
    overflow-x: hidden;
} */
/* .container-main{
    width: 100%;
    overflow-x: hidden;
} */

section {
    font-family: 'Poppins',sans-serif;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    background: #001923;

}

section::before {
    content: '';
    position: absolute;
    width: 400px;
    height: 400px;
    background: linear-gradient(#ffeb3b, #e91e63);
    border-radius: 50%;
    transform: translate(-420px,-180px);
}

section::after {
    content: '';
    position: absolute;
    width: 350px;
    height: 350px;
    background: linear-gradient(#2196f3, #88df72);
    border-radius: 50%;
    transform: translate(500px, 150px);
}

.container {
    position: relative;
    margin-top: 80px;
    z-index: 1000;
    width: 100%;
    max-width: 1000px;
    padding: 50px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    overflow: hidden;
    backdrop-filter: blur(25px);
}

.container::before {
    content: '';
    top: 0;
    position: absolute;
    left: -40%;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.05);
    pointer-events: none;
    transform: skewX(-15deg);
}

.container h1 {
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 36px;
    margin-bottom: 20px;
}

.container .row100 {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px,1fr));
}

.container .row100 .col {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    padding: 0 10px;
    margin: 30px 0 20px ;
}

.container .row100 .col .inputBox {
    position: relative;
    width: 100%;
    height: 40px;
    color: #fff;
}

.container .row100 .col .inputBox span.text, 
.container .row100 .col .inputBox textarea span.text {
    font-size: 20px;
    font-family: 'Poppins',sans-serif;
    font-style: bold;
}

.container .row100 .col .inputBox input, 
.container .row100 .col .inputBox textarea {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    box-shadow: none;
    border: none;
    outline: none;
    font-size: 20px;
    padding: 0 10px;
    z-index: 1;
    color: #000;
}

.container .row100 .col .inputBox .text {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 40px;
    font-size: 20px;
    padding: 0 10px;
    display: block;
    transition: 0.5s;
    pointer-events: none;
}

.container .row100 .col .inputBox input:focus + .text,
.container .row100 .col .inputBox input:valid + .text,
.container .row100 .col .inputBox textarea:focus + .text,
.container .row100 .col .inputBox textarea:valid + .text 
{
    top: -35px;
    left: -10px;
}

.container .row100 .col .inputBox .line {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    transition: 0.5s;
    border-radius: 2px;
    pointer-events: none;
} 

.container .row100 .col .inputBox input:focus ~ .line,
.container .row100 .col .inputBox input:valid ~ .line,
.container .row100 .col .inputBox textarea:focus ~ .line,
.container .row100 .col .inputBox textarea:valid ~ .line {
    height: 100%;
}

.container .row100 .col .inputBox.textarea {
    position: relative;
    width: 100%;
    height: 100px;
    padding: 10px 0;
}

.container .row100 .col button {
    border: none;
    padding: 10px 40px;
    cursor: pointer;
    outline: none;
    background: #fff;
    color: #000;
    font-weight: 600;
    font-size: 20px;
    border-radius: 2px;
}

@media screen and (max-width: 800px)
{
    section::before {
        transform: translate(-200px, -180px);
    }

    section::after {
        transform: translate(0, 100px);
    }

    .container {
        padding: 20px;
    }

    .container h1 {
        font-size: 28px;
    }
    .container {
        margin-top: 100px;
        margin-bottom: 20px;
        margin-left: 12px;
        margin-right: 12px;
    }
}

@media screen and (max-width: 1350px) {
    .container {
        margin-top: 100px;
        margin-bottom: 20px;
        margin-left: 12px;
        margin-right: 12px;
    }
    section::after {
        transform: translate(0, 100px);
    }
}

.head-text{
    text-align: center;
    color: white;
    font-size: 1.2rem;
}

